import React, { useEffect, useMemo } from 'react';
import TopBanner from './top_banner';
import globalHelper from '../../helpers/functions/global.helper';

const TopBannerNormalTicket = ({ park, bannerHeight, setBanner, }) => {
    const bannerQuill = useMemo(() => {
        let banner_quill;
        try{
            banner_quill = JSON.parse(park?.banner_text)
        }catch(err){

        }
        return banner_quill;
    }, [park])

    // if banner quill existing, then set to wrapper to tell it
    useEffect(() => {
        if(bannerQuill){
            setBanner?.(true);
        }
    }, [bannerQuill])

    return (
        <>
            {
                bannerQuill && (
                    <>
                        <TopBanner height={bannerHeight} bannerQuill={bannerQuill} type='info' />
                    </>
                )
            }
        </>
    );
}

export default TopBannerNormalTicket;