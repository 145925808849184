import React, { useEffect, useMemo, useState } from 'react';
import parkService from '../../services/config/park.service';
import globalHelper from '../../helpers/functions/global.helper';
import TopBanner from './top_banner';

// This wrapper is to differentiate between normal ticket and open date ticket
const TopBannerOpenDate = ({ park, bannerHeight, setBanner, }) => {
    // const bannerHeight = globalHelper?.isMobileScreen() ? 100 : 40;
    const [state, setState] = useState({
        loading : false,
        park_open_date_setting : null,
    })

    const bannerQuill = useMemo(() => {
        let banner_quill;
        try{
            banner_quill = JSON.parse(state?.park_open_date_setting?.description)
        }catch(err){

        }
        return banner_quill;
    }, [state?.park_open_date_setting])

    const fetchParkOpenDateSetting = async () => {
        setState(state => ({...state, loading : true, }))
        const parkOpenDateSettingResponse = await parkService?.getParkOpenDateSetting(park?.park_id);

        const { park_open_date_setting } = parkOpenDateSettingResponse;
        setState(state => ({...state, loading : false, park_open_date_setting, }))
    }

    useEffect(() => {
        fetchParkOpenDateSetting();
    }, [park?.park_id])
    
    // if banner quill existing, then set to wrapper to tell it
    useEffect(() => {
        if(bannerQuill){
            setBanner?.(true);
        }
    }, [bannerQuill])

    return (
        <>
            {
                bannerQuill && (
                    <>
                        <TopBanner height={bannerHeight} bannerQuill={bannerQuill} type='success' />
                    </>
                )
            }
        </>
    );
}

export default TopBannerOpenDate;